<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="HCF_FACILITY_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hcfFacilityTypeCd"
            label="시설유형"
            v-model="searchParam.hcfFacilityTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="fstTable"
          title="검사유형 목록"
          tableId="fstTable"
          rowKey="iimOccurMasterId"
          :isExcelDown="false"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grid1.columns"
          :data="grid1.data"
          :editable="firstEditable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="firstEditable" 
                label="" 
                icon="add" 
                :showLoading="false" 
                @btnClicked="addrow1" />
              <c-btn
                v-if="firstEditable && grid1.data.length > 0"
                :url="classSaveUrl"
                :isSubmit="isSave1"
                :param="grid1.data"
                mappingType="PUT"
                label=""
                icon="save"
                @beforeAction="save1"
                @btnCallback="saveCallback1" />
              <c-btn label="" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'click'">
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                color="blue"
                outline square
                :clickable="true"
                :editable="editable"
                text-color="white"
                @click="rowClick(props.row, col.name)">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="secTable"
          title="검사내용 목록"
          tableId="secTable"
          rowKey="iimOccurMasterId"
          :isExcelDown="false"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grid2.columns"
          :data="grid2.data"
          :editable="secondEditable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "검사유형 : " + popupData.inspectionClassName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="secondEditable" 
                label="추가" 
                icon="add" 
                :showLoading="false"
                @btnClicked="addrow2" />
              <c-btn
                v-if="secondEditable && grid2.data.length > 0"
                :url="itemSaveUrl"
                :isSubmit="isSave2"
                :param="grid2.data"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveClass2"
                @btnCallback="saveCallback2" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-facility-check-item',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        hcfFacilityTypeCd: null,
      },
      grid1: {
        columns: [],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'contents',
            field: 'contents',
            label: '검사내용',
            child: [
              {
                name: 'mainCheckItemName',
                field: 'mainCheckItemName',
                label: '대분류',
                type: 'textarea',
                style: 'width:400px',
                align: 'left',
                sortable: false,
              },
              {
                name: 'middleCheckItemName',
                field: 'middleCheckItemName',
                label: '중분류',
                type: 'textarea',
                style: 'width:400px',
                align: 'left',
                sortable: false,
              },
            ]
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'number',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:40px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      popupData: {
        first: '',
        inspectionClassName: '',
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave1: false,
      isSave2: false,
      classListUrl: '',
      itemListUrl: '',
      classSaveUrl: '',
      itemSaveUrl: '',
    };
  },
  computed: {
    firstEditable() {
      return this.editable;
    },
    secondEditable() {
      return this.editable && Boolean(this.popupData.first);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.classListUrl = selectConfig.env.facilityInspectionClass.list.url;
      this.itemListUrl = selectConfig.env.facilityCheckItem.list.url;
      this.classSaveUrl = transactionConfig.env.facilityInspectionClass.save.url;
      this.itemSaveUrl = transactionConfig.env.facilityCheckItem.save.url;
      // code setting
      this.$comm.getComboItems('HCF_FACILITY_TYPE_CD').then(_result => {
        this.$comm.getComboItems('HCF_INSPECTION_TYPE_CD').then(__result => {
          this.grid1.columns = [
            {
              name: 'hcfFacilityTypeCd',
              field: 'hcfFacilityTypeCd',
              label: '시설유형',
              align: 'left',
              style: 'width:85px',
              sortable: false,
              type: 'select',
              comboItems: _result,
              reqired: true,
            },
            {
              name: 'hcfInspectionTypeCd',
              field: 'hcfInspectionTypeCd',
              label: '검사종류',
              align: 'left',
              style: 'width:85px',
              sortable: false,
              type: 'select',
              comboItems: __result,
              reqired: true,
            },
            {
              name: 'inspectionClassName',
              field: 'inspectionClassName',
              label: '검사유형',
              align: 'left',
              style: 'width:120px',
              sortable: false,
              type: 'text',
              reqired: true,
            },
            {
              name: 'useFlag',
              field: 'useFlag',
              label: '사용여부',
              align: 'center',
              style: 'width:40px',
              type: 'check',
              true: 'Y',
              false: 'N',
              sortable: false,
            },
            {
              name: 'sortOrder',
              field: 'sortOrder',
              label: '순번',
              align: 'center',
              style: 'width:40px',
              sortable: false,
              type: 'number',
            },
            {
              name: 'click',
              field: 'click',
              label: '중분류',
              align: 'center',
              style: 'width:50px',
              sortable: false,
              type: "custom",
            },
          ]
        });
      });
      // list setting
      // 점검유형 데이터 받은 후 조회 처리
      this.getList();
    },
    getList() {
      this.$http.url = this.classListUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
        this.grid2.data = []
        Object.assign(this.$data.popupData, this.$options.data().popupData);
      },);
    },
    rowClick(row) {
      if (!row.hcfHazardFacilityInspectionClassId 
        || row.editFlag === 'C'
        || row.hcfHazardFacilityInspectionClassId === this.popupData.first) return;
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        hcfHazardFacilityInspectionClassId: row.hcfHazardFacilityInspectionClassId,
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
        this.popupData.first = row.hcfHazardFacilityInspectionClassId;
        this.popupData.inspectionClassName = row.inspectionClassName;
      },);
    },
    /* eslint-disable no-unused-vars */
    addrow1() {
      this.grid1.data.splice(0, 0, {
        hcfHazardFacilityInspectionClassId: uid(),  // 유해화학자재 취급 시설 검사 유형 번호
        hcfFacilityTypeCd: null,  // 시설유형 코드
        hcfInspectionTypeCd: null,  // 검사종류 코드
        inspectionClassName: '',  // 검사유형명
        sortOrder: 0,  // 정렬순서
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    save1() {
      if (this.$comm.validTable(this.grid1.columns, this.grid1.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave1 = !this.isSave1;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow2() {
      this.grid2.data.splice(0, 0, {
        hcfHazardFacilityCheckItemId: uid(),  // 유해화학자재 취급 시설 검사 항목 번호
        hcfHazardFacilityInspectionClassId: this.$_.clone(this.popupData.first),  // 유해화학자재 취급 시설 검사 유형 번호
        mainCheckItemName: '',  // 검사유형명(대분류)
        middleCheckItemName: '',  // 검사유형명(중분류)
        sortOrder: 0,  // 정렬순서
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    saveClass2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick({ 
        hcfHazardFacilityInspectionClassId: this.popupData.first,
        inspectionClassName: this.popupData.inspectionClassName 
      })
    },
  }
};
</script>
